import posterVisuall from "../../images/poster-video.jpg"
import ButtonPlayMaskSVG from "../SVG/ButtonPlayMaskSVG"
import SimplifySvg from "../SVG/SimplifySVG"
import React from "react"

const VideoCloudinary = () => {
  return (
    <video
      className="w-full aspect-video video-visuall"
      poster={posterVisuall}
      muted
      autoPlay="autoplay"
      preload="auto"
      loop
    >
      <source
        type="video/mp4"
        src="https://res.cloudinary.com/eidolon/video/upload/v1666258865/Visuall-CH/visuall_home.mp4"
      />
    </video>
  )
}

export function HeroSection() {
  return (
    <section className="lg:py-12">
      <div className="max-w-[1920px] mx-auto">
        <div className="lg:mb-32">
          <div className="grid grid-cols-12 grid-rows-1 px-2 lg:px-0">
            <div className="row-start-1 row-end-2 col-start-1 col-end-13 video-visuall-mask w-full min-h-[400px] lg:min-h-[600px] lg:col-start-2 lg:col-end-11 ">
              <VideoCloudinary />
              <ButtonPlayMaskSVG />
            </div>
            <div className="relative z-20 row-start-1 row-end-2 col-start-7 col-end-13 lg:col-end-11 self-center lg:col-start-7">
              <h1 className="text-3xl text-center lg:text-left lg:text-5xl xl:text-6xl font-archia lg:-ml-12">
                <SimplifySvg />
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
