import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import VideoHome from "../components/VideoHome"
import Home from "../components/Home"
import ContactFormSection from "../components/ContactFormSection"
import HomeBlockVideo from "../components/Home/HomeBlockVideo"

const IndexPage = ({ data }) => {
  const HOME = data.datoCmsHomePage
  return (
    <Layout>
      <Seo
        title={HOME.titre}
        description={HOME.description}
        lang={HOME.locale}
      />
      <Home home={HOME} />
      <ContactFormSection />
    </Layout>
  )
}

export const query = graphql`
  {
    datoCmsHomePage {
      titre
      description
      locale
      seoMetaTags {
        tags
      }
      sections {
        illustration {
          gatsbyImageData
        }
        category {
          color {
            hex
            rgb
          }
          slug
          title
        }
        titreHtml
      }
    }
  }
`

export default IndexPage
